<template>
  <div>
    <Banner v-if="isMobile" />
    <HomePopup v-if="isOpenPopup" :isMobile="isMobile" />
    <HomeExpansionPanels />
    <Activity :isShowMore="true" />
    <!-- <DynamicMarquee /> -->
    <!-- <BestVideoDynamicMarquee /> -->
    <Footer :socialMedia="socialMedia" />
  </div>
</template>
<script>
// @ is an alias to /src
import DynamicMarquee from "@/components/organisms/DynamicMarquee.vue";
import BestVideoDynamicMarquee from "@/components/organisms/BestVideoDynamicMarquee.vue";
import YoutubeBanner from "@/components/organisms/YoutubeBanner.vue";
import Activity from "@/components/organisms/Activity.vue";
import HomeExpansionPanels from "@/components/atoms/HomeExpansionPanels.vue";
import Footer from "@/components/organisms/Footer.vue";
import Banner from "@/components/organisms/Banner.vue";
import HomePopup from "@/components/organisms/HomePopup.vue";
import config from "../../../config";
import axios from "axios";
import moment from "moment";

export default {
  name: "Home",
  components: {
    // HelloWorld
    DynamicMarquee,
    YoutubeBanner,
    Activity,
    HomeExpansionPanels,
    Footer,
    Banner,
    HomePopup,
    BestVideoDynamicMarquee,
  },
  created() {
    this.fetchData();
    this.getPopup();
  },
  methods: {
    isRunningEventEnd() {
      const today = moment();
      const november31st = moment("11/31", "MM/DD");
      return today.isAfter(november31st);
    },
    fetchData() {
      axios
        .get(config.wordpressUrl + `/configs?order=asc&per_page=100`)
        .then((response) => {
          let data = response.data[0];

          if (data != null) {
            this.socialMedia = data.acf.social_media;
          }
        });
    },
    getPopup(){
      axios
        .get(config.wordpressUrl + `/configs?order=asc&per_page=100`)
        .then((response) => {
          let data = response.data[0];

          // console.log("my testing!!", data.acf.isopenpopup);

          if(data.acf.isopenpopup.isopen == "Y"){
              this.isOpenPopup = true
          }
        });
    }
  },
  data: () => ({
    padless: false,
    variant: "default",
    socialMedia: [],
    isOpenPopup: false,
  }),
};
</script>
