var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-main',{staticClass:"pb-0"},[_c('AgeBar'),_c('ContentHeader'),(!['Home'].includes(_vm.currentRouteName))?_c('v-container',{style:({
        position: [
          'KidsHelpingHand',
          'TeensHelpingHand',
          'AdultHelpingHand',
        ].includes(_vm.currentRouteName)
          ? 'absolute'
          : 'relative',
        'z-index': '5',
      })},[_c('div',{staticClass:"text-right"},[_c('v-btn',{staticClass:"white--text",attrs:{"color":"blue-grey darken-1"},on:{"click":function($event){return _vm.share()}}},[_vm._v(" "+_vm._s(_vm.$t("common.share"))+" "),_c('v-icon',{attrs:{"right":"","dark":""}},[_vm._v(" mdi-share ")])],1)],1),_c('v-snackbar',{attrs:{"timeout":2000},scopedSlots:_vm._u([{key:"action",fn:function({ attrs }){return [_c('v-btn',_vm._b({attrs:{"color":"blue","text":""},on:{"click":function($event){_vm.snackbar = false}}},'v-btn',attrs,false),[_vm._v(" "+_vm._s(_vm.$t("common.close"))+" ")])]}}],null,false,1582882355),model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(" "+_vm._s(_vm.$t("common.copiedUrl"))+" ")])],1):_vm._e(),(['Home'].includes(_vm.currentRouteName))?_c('div',[(_vm.isMobile && _vm.isRunningEventEnd())?_c('v-img',{staticClass:"d-flex align-center justify-center",staticStyle:{"margin":"auto"},attrs:{"src":require('@/assets/images/home_header_bkg.png'),"cover":""}}):_vm._e(),(_vm.showCarousel)?_c('BaseCarousel',{attrs:{"isMobile":!_vm.isMobile,"desktopItems":_vm.desktoplItems,"mobileItems":_vm.mobileItems,"interval":4000}}):_vm._e(),(!_vm.showCarousel)?[_c('v-container',{attrs:{"fluid":"","fill-height":"","pa-0":""}},[_c('v-layout',{attrs:{"fill-height":"","wrap":"","column":""}})],1),(!_vm.isMobile)?_c('v-img',{staticStyle:{"margin":"auto"},attrs:{"src":_vm.getBannerBackground(),"contain":""}},[_c('v-container',{attrs:{"fluid":"","fill-height":"","pa-0":""}},[_c('v-layout',{attrs:{"fill-height":"","row":"","wrap":"","align-end":""}},[(!_vm.isMobile)?_c('Banner',{attrs:{"bannerDesc":_vm.bannerDesc,"banners":_vm.banners}}):_vm._e()],1)],1)],1):_vm._e()]:_vm._e()],2):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }